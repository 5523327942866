
     export const 
     server = 'https://beta.lglship.com/' 
         //server = '/' //lglangnode using proxy

          //  server = "https://lglship-brownrlb.c9users.io/"
     //  server = 'http://lglnode.lglship.com/'//lglship
     ;

      
    
