import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {server} from './constants';

@Injectable({
  providedIn: 'root'
})
export class PalletServiceService {
  private httpAddress = server;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  }

  constructor(private http: HttpClient) { }


getSelectedpallet(palletid): Promise<any>{
  // get the selected client then return info also put in localStorage
  return this.http.get(this.httpAddress + 'api/palletvw/' + palletid)
  .toPromise()
  .then(pallet => pallet)
  .catch(err => console.log(err))
}

getLikepalletid(palletid): Promise<any>{
  // get list of palletids like pallet id limit 20 return list
  return this.http.get(this.httpAddress + 'api/getid/' + palletid)
  .toPromise()
  .then(palletlist => palletlist)
  .catch(err => console.log(err));
}

getLast10(): Promise<any>{
  // get list of palletids like pallet id limit 10 return list
  return this.http.get(this.httpAddress + 'api/getlast10')
  .toPromise()
  .then(palletlist => palletlist)
  .catch(err => console.log(err));
}

getBol200() {
    // get list of bols desc return list of 200
    return this.http.get(this.httpAddress + 'api/getbols200')
    .toPromise()
    .then(bollist => bollist)
    .catch(err => console.log(err));
}

getLikeClient(keyIn): Promise<any>{
  // get list of palletids like pallet id limit 20 return list
  return this.http.get(this.httpAddress + 'api/getclientlike/' + keyIn)
  .toPromise()
  .then(clientlist => clientlist)
  .catch(err => console.log(err));
}

getSubClients(clientid): Promise<any>{
  // get list of palletids like pallet id limit 20 return list
  return this.http.get(this.httpAddress + 'api/subclients/' + clientid)
  .toPromise()
  .then(subclients => subclients)
  .catch(err => console.log(err));
}

postUpdatePallet(pallet) {
  // http to post pallet data to node to update pallet by id
  return this.http.post(this.httpAddress + 'api/updatepallet', pallet)
  .toPromise()
  .then(result => result)
  .catch(err => console.log(err));
}

uploadImage(image: File, palletid) {
  const formData = new FormData();

  formData.append('myFile', image);
  formData.append('pallet', palletid);

  return this.http.post(this.httpAddress + 'api/uploadfile', formData)
  .toPromise()
  .then(result => result)
  .catch(err => console.log(err));
}

getPictureLinks(palletid): Promise<any> {
  // get list of palletids like pallet id limit 20 return list
  return this.http.get(this.httpAddress + 'api/palletpictures/' + palletid)
  .toPromise()
  .then(palletlinks => palletlinks)
  .catch(err => console.log(err));
}

ocrScan(bucket, filename, palletid): Promise<any> {
  // use node and google vision to scan picture
  return this.http.get(`${this.httpAddress}api/gcsocr/${bucket}/${filename}/${palletid}`)
  .toPromise()
  .then(ocrText => ocrText)
  .catch(err => console.log(err));
}

stagePallet(pallet): Promise <any> {
    // save pallet info and desc to tblstaged return
  return this.http.post(this.httpAddress + 'api/stagepallet', pallet)
  .toPromise()
  .then(result => result)
  .catch(err => console.log(err));
}

getStaged(): Promise<any> {
  // get list of palletids like pallet id limit 20 return list
  return this.http.get(this.httpAddress + 'api/staged')
  .toPromise()
  .then(staged => staged)
  .catch(err => console.log(err));
  }

removeStaged(stagedid): Promise<any> {
  // get list of palletids like pallet id limit 20 return list
  return this.http.get(this.httpAddress + `api/removestaged/${stagedid}`)
  .toPromise()
  .then(result => result)
  .catch(err => console.log(err));
  }

getClientList(): Promise<any> {
  // get client list from lgltrax.
  return this.http.get(this.httpAddress  + 'api/clients/get')
  .toPromise()
  .then(clients => clients)
  .catch(err => console.log(err));
  }

getClientAdresses(clientid): Promise<any> {
  // get client list from lgltrax.
  return this.http.get(this.httpAddress  + `api/addresses/get/${clientid}`)
  .toPromise()
  .then(addresses => addresses)
  .catch(err => console.log(err));
  }

createBol(postBol): Promise <any> {
    // save pallet info and desc to tblstaged return
  return this.http.post(this.httpAddress + 'api/quickbol', postBol)
  .toPromise()
  .then(result => result)
  .catch(err => console.log(err));
}

insertShipItem(item): Promise <any> {
  return this.http.post(this.httpAddress + 'api/shipdetails', item)
  .toPromise()
  .then(result => result )
  .catch(err => console.log(err));
}


}



