import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'scan', loadChildren: './scan/scan.module#ScanPageModule' },
  { path: 'palletpicture/:palletid', loadChildren: './pallet/palletpicture/palletpicture.module#PalletpicturePageModule' },
  { path: 'viewpictures/:palletid', loadChildren: './viewpictures/viewpictures.module#ViewpicturesPageModule' },
  { path: 'palletclient/:palletid', loadChildren: './pallet/palletclient/palletclient.module#PalletclientPageModule' },
  { path: 'palletstart/:palletid', loadChildren: './pallet/palletstart/palletstart.module#PalletstartPageModule' },
  { path: 'palletstart/:palletid/shipit', loadChildren: './pallet/shippallet/shippallet.module#ShippalletPageModule' },
  { path: 'palletstart/:palletid/view', loadChildren: './pallet/viewpallet/viewpallet.module#ViewpalletPageModule' },
  { path: 'palletstart/:palletid/edit', loadChildren: './pallet/editpallet/editpallet.module#EditpalletPageModule' },
  { path: 'palletocr/:bucket/:filename/:palletid', loadChildren: './pallet/palletocr/palletocr.module#PalletocrPageModule' },
  { path: 'palletstaged', loadChildren: './pallet/palletstaged/palletstaged.module#PalletstagedPageModule' },
  { path: 'videos', loadChildren: './videos/videos.module#VideosPageModule' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
